.rich-text-editor {
    .ql-toolbar.ql-snow {
        @apply border border-gray-300 dark:border-gray-600 rounded-tl-lg rounded-tr-lg;
    }

    .ql-container.ql-snow {
        @apply border border-gray-300 dark:border-gray-600 rounded-bl-lg rounded-br-lg;
    }

    .ql-editor {
        @apply min-h-[120px] p-6;
    }

    .ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button {

    }

    .ql-snow .ql-stroke {
        @apply stroke-gray-600 dark:stroke-gray-100;
    }

    .ql-snow .ql-picker {
        @apply text-gray-600 dark:text-gray-100;
    }
    
    .ql-snow.ql-toolbar button, 
    .ql-snow .ql-toolbar button {
        @apply rounded;
    }

    .ql-snow.ql-toolbar button:hover, 
    .ql-snow .ql-toolbar button:hover, 
    .ql-snow.ql-toolbar button:focus, 
    .ql-snow .ql-toolbar button:focus, 
    .ql-snow.ql-toolbar button.ql-active, 
    .ql-snow .ql-toolbar button.ql-active, 
    .ql-snow.ql-toolbar .ql-picker-label:hover, 
    .ql-snow .ql-toolbar .ql-picker-label:hover, 
    .ql-snow.ql-toolbar .ql-picker-label.ql-active, 
    .ql-snow .ql-toolbar .ql-picker-label.ql-active, 
    .ql-snow.ql-toolbar .ql-picker-item:hover, 
    .ql-snow .ql-toolbar .ql-picker-item:hover, 
    .ql-snow.ql-toolbar .ql-picker-item.ql-selected, 
    .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
        @apply bg-gray-100 dark:bg-gray-500;
    }

    .ql-snow.ql-toolbar button:hover .ql-stroke, 
    .ql-snow .ql-toolbar button:hover .ql-stroke, 
    .ql-snow.ql-toolbar button:focus .ql-stroke, 
    .ql-snow .ql-toolbar button:focus .ql-stroke, 
    .ql-snow.ql-toolbar button.ql-active .ql-stroke, 
    .ql-snow .ql-toolbar button.ql-active .ql-stroke, 
    .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, 
    .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, 
    .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, 
    .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, 
    .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, 
    .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, 
    .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, 
    .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, 
    .ql-snow.ql-toolbar button:hover .ql-stroke-miter, 
    .ql-snow .ql-toolbar button:hover .ql-stroke-miter, 
    .ql-snow.ql-toolbar button:focus .ql-stroke-miter, 
    .ql-snow .ql-toolbar button:focus .ql-stroke-miter, 
    .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, 
    .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, 
    .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, 
    .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, 
    .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, 
    .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, 
    .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, 
    .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, 
    .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, 
    .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
        @apply stroke-gray-900 dark:stroke-gray-100;
    }

    .ql-snow .ql-picker-options .ql-picker-item {
        @apply px-2 rounded-lg;
    }

    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
        @apply border-gray-300 dark:border-gray-600
    }

    .ql-snow .ql-picker-options {
        @apply bg-white dark:bg-gray-700 rounded-lg;
    }

    .ql-snow.ql-toolbar button:hover, 
    .ql-snow .ql-toolbar button:hover, 
    .ql-snow.ql-toolbar button:focus, 
    .ql-snow .ql-toolbar button:focus, 
    .ql-snow.ql-toolbar button.ql-active, 
    .ql-snow .ql-toolbar button.ql-active, 
    .ql-snow.ql-toolbar .ql-picker-label:hover, 
    .ql-snow .ql-toolbar .ql-picker-label:hover, 
    .ql-snow.ql-toolbar .ql-picker-label.ql-active, 
    .ql-snow .ql-toolbar .ql-picker-label.ql-active, 
    .ql-snow.ql-toolbar .ql-picker-item:hover, 
    .ql-snow .ql-toolbar .ql-picker-item:hover, 
    .ql-snow.ql-toolbar .ql-picker-item.ql-selected, 
    .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
        @apply text-gray-900 dark:text-gray-100;
    }
}