.guid-cell {
  transform: scaleX(0.70);
  margin-left: -44px;
  margin-right: -46px;
  min-width: 200px;
}

.warning-bg {
  .dialog-content {
    background-color: #2b2727;
    color: white;
  }
}

.color-pick {
  display: inline-block;
  width: 220px;
  button {
    width: 32px;
    height: 32px;
    border-width: 1px;
  }
}

.cc-img {
  max-width: 65px;
  max-height: 40px;
}

.map-main {
  height: calc(100VH - 400px);
  width: calc(100% - 48px);
  margin-left: 0px;
  margin-right: 24px;
  max-width: 920px;
}

.min-dd {
  width: 240px;
  display: inline-block;
}

.main-input {
  width: 280px;
  display: inline-block;
  margin-left: 18px;
}

.map-header {
  text-align: left;
  width: 100%;
  display: block;
  padding: 12px 24px;
}

.map-loading img {
  max-height: 36px;
}
.map-loading {
  display: inline-block;
  vertical-align: top;
  line-height: 34px;
}

.media-file-uploader .upload-file-list {
  margin-top: 8px;
}

@media (min-width: 800px) {
  .dialog {
    max-width: calc(100% - 120px) !important;
  }
}
@media (max-width: 800px) {
  .dialog {
    max-width: calc(100% - 40px) !important;
  }
}

.truncate {
  display: inline-block;
  white-space: nowrap; /* Keep the text in a single line */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Add ellipsis to overflow text */
}

.media-file-icons {
  display: block;
}

.media-file-icons svg {
  font-size: x-large;
  margin-top: 2px;
  margin-left: 6px;
  margin-right: 2px;
  margin-bottom: 6px;
  cursor: pointer;
}

.media-file-icons span {
  display: inline-block;
}

#root {
  overflow-y: hidden;
}

.register-form-wrapper {
  width: 540px;
  @media (max-width: 600px) {
    width: calc(100vw - 40px);
  }
  .input-md {
    margin-top: -4px;
  }
  .form-item {
    margin-bottom: 24px;
  }
}

.multi-text-style {
  width: 100%;
  border-width: 1px;
  border-radius: 8px;
  border-color: #cdc3c3;
  padding: 8px;
  margin-bottom: -8px;
}


.conversations-history {
  div .cs-message-list__typing-indicator-container div {
    color: #676767;
  }

  div .cs-main-container,
  div .cs-chat-container .cs-message-input {
    border: 0px;
  }

  div .cs-message--incoming .cs-message__content,
  div .cs-chat-container .cs-message-input,
  div .ps__thumb-y,
  div .ps__rail-y:hover .ps__thumb-y,
  div .ps__rail-y:active .ps__thumb-y {
    background-color: #ececec;
  }

  div .cs-message-input__content-editor,
  div .cs-message-input__content-editor-wrapper,
  div .cs-message-list,
  div .ps__rail-y:hover,
  div .ps .ps__rail-y:hover,
  div .ps__rail-y {
    background-color: #fafafa;
  }

  div .cs-message--outgoing .cs-message__content {
    background-color: #efefef;
  }

  div .cs-typing-indicator__dot {
    background-color: #000f6d;
  }
}

.checkbox.indeterminate {
  background-color: lightgray;
  border: 2px solid gray;
  position: relative;
}

.checkbox.indeterminate::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 2px;
  background-color: black;
  transform: translate(-50%, -50%);
}

.narrow-header-text {
  white-space: nowrap;
  letter-spacing: -0.6px;
  margin-left: -5px;
  margin-right: -5px;
}

.no-lastpass div[data-lastpass-icon-root] {
  display: none !important;
}
