.apexcharts-tooltip {
    @apply !shadow-lg;

    &.apexcharts-theme-light {
       @apply !border !border-gray-200 dark:!border-gray-600 !bg-white dark:!bg-gray-600;

        .apexcharts-tooltip-title {
            @apply !border-b-0 !bg-transparent;
        }
    }
}

.apexcharts-canvas {
    @apply mx-auto
}

.apexcharts-datalabel-label {
    @apply !fill-gray-900 dark:!fill-gray-100 text-2xl font-bold;
}

.apexcharts-datalabel-value {
    @apply !fill-gray-500 dark:!fill-gray-400 text-base;
}

.apexcharts-tooltip-title {
    @apply !mb-0;
}

.apexcharts-gridline {
    @apply stroke-gray-200 dark:stroke-gray-600
}

.apexcharts-text {
    tspan {
        @apply !fill-gray-500 dark:!fill-gray-400;
    }
}

.apexcharts-menu-item {
    @apply text-left
}

.apexcharts-xaxistooltip {
    @apply !opacity-0;
}

.apexcharts-legend-text {
    @apply !text-gray-500 dark:!text-gray-400 !text-sm !font-normal ;
}

.source-pie-chart .apexcharts-legend-text {
    display: inline-block;
    max-width: 140px; /* Adjust as needed */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.source-pie-chart .apexcharts-legend-marker {
    top: -4px !important;
}

.source-pie-chart-card .card-body {
    padding: 4px;
    padding-right: 12px;
}

.source-pie-chart-card .card-body h6 {
    margin-top: 8px;
    margin-bottom: -8px;
    margin-left: 18px;
}

.source-pie-chart-card {
    min-width: 480px;
    margin-right: 8px;
    margin-bottom: 8px;
}

.source-pie-chart-block {
    @media screen and (min-width: 1350px) {
        width: calc(50% - 4px);
    }
}
